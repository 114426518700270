// 案例课程首页数据
import { CaseCenter, CaseTopics } from '@api/mock/mockData'
import { CategoryList, SubClassList } from '@api/mock/caseCenter'


export const newsList = [
  {
    id: 'GBACPC015',
    coverPictureUrl: require('@/assets/index/news-img15.jpg'),
    date: '2024-08-22',
    publishTime: '2024-08-22 08:42:59',
    source: '湾区认证促进中心',
    views: '4038',
    title: '促进中心承办顺德区水产品质量安全研讨会',
    content: '8月20日上午，由广东省市场监督管理局指导、广东粤港澳大湾区认证促进中心等单位承办的水产品质量安全研讨会在顺德区政府会议中心顺利举行。',
    htmlContent: `<div style="text-indent: 2rem;margin: 10px 0">8月20日上午，由广东省市场监督管理局指导、广东粤港澳大湾区认证促进中心等单位承办的水产品质量安全研讨会在顺德区政府会议中心顺利举行。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img15.jpg')}" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">广东省市场监督管理局党组成员、省知识产权保护中心党委书记、主任高国盛，省市场监督管理局认监处副处长郭廷洲，顺德区政府副区长陈振浩，顺德区市场监督管理局局长吴显强、党组成员何小林等,以及来自中山大学、中国科学院水生生物研究所的多位专家、教授，经营顺德鱼生的多家区内餐饮企业代表和南沙、茂名等地多家渔业养殖企业代表出席会议。</div>`
  },
  {
    id: 'GBACPC011',
    coverPictureUrl: require('@/assets/index/news-img6.jpg'),
    date: '2024-08-19',
    publishTime: '2024-08-19 10:02:59',
    source: '湾区认证促进中心',
    views: '4038',
    title: '湾区认证促进中心参加2024年南沙区湾区认证宣贯会暨中小微企业质量管理体系认证培训会',
    content: '8月16日下午，湾区认证促进中心在大湾区（南沙）质量品牌创新基地参加由南沙区市场监督局主办的南沙区湾区认证宣贯会暨中小微企业质量体系认证培训会。多家企业认证及质量业务相关负责人代表参加本次培训',
    htmlContent: `<div style="text-indent: 2rem;margin: 10px 0">8月16日下午，湾区认证促进中心在大湾区（南沙）质量品牌创新基地参加由南沙区市场监督局主办的南沙区湾区认证宣贯会暨中小微企业质量体系认证培训会。多家企业认证及质量业务相关负责人代表参加本次培训。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img6.jpg')}" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">此次培训旨在以高品质认证引领产业优化升级，提升南沙区企业运用科学管理手段提升自身质量的能力以及产品服务竞争力，促进内地和港澳地区优质产品和服务高效流通。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img7.png')}" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">湾区认证促进中心运营总监陆永驰从“什么是湾区认证”“湾区认证实体化运作情况简介”“湾区认证业务流程”三个方面对“湾区认证”进行了详细的解读。其中，着重介绍了“湾区认证”特有的三项制度：质量信息公示、承诺赔付和销售商首付责任制度，强调了“湾区认证”具有“双刃剑”的作用：一方面，帮助商家增加产品销量，重振消费信心；另一方面，时刻监督商家，严格管控产品质量，实现消费者“优选”“盲选”。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img8.jpg')}" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">“湾区认证”是认真贯彻落实习近平总书记重要讲话精神，推进粤港澳大湾区建设的重点工作安排，利用信息化、数字化手段全面、直观、便捷地向消费者展示产品质量信息，成为高品质产品和服务的可信赖的标志。</div>`
  },
  {
    id: 'GBACPC012',
    coverPictureUrl: require('@/assets/index/news-img10.jpg'),
    date: '2024-08-17',
    publishTime: '2024-08-17 16:36:59',
    source: '湾区认证促进中心',
    views: '4038',
    title: '湾区认证促进中心参加茂名市“湾区认证”推动品牌建设培训会',
    content: '8月15日下午，茂名市“信”字号公用品牌领导小组办公室主办的2024年“湾区认证”推动品牌建设培训会在茂名市市场监督局二楼北会议室成功举行。',
    htmlContent: `<div style="text-indent: 2rem;margin: 10px 0">8月15日下午，茂名市“信”字号公用品牌领导小组办公室主办的2024年“湾区认证”推动品牌建设培训会在茂名市市场监督局二楼北会议室成功举行。来自茂名市农业农村局、市区县市场监管局的领导、“信”字号公用品牌企业代表以及60余茂名市优秀农业和食品加工企业负责人参与本次培训，共同探讨如何通过湾区认证加速与“信”字号公用品牌的融合，以及两者促进茂名市优质土特产的品牌建设和市场拓展的规划。</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">会议旨在深入实施“百县千镇万村高质量发展工程”，赋能茂名特色食品、农产品高质量发展。会议强调了湾区认证的重要性，它不仅是产品走向全国乃至世界的通行证，更是提升产品竞争力的关键。通过“信”字号品牌的打造，结合湾区认证，将进一步推动茂名市企业的品牌化发展。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img10.jpg')}" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">何金同志提到，“信”字号，是由信宜县两年的积极探索上升为茂名市的公用品牌，其以高质量实施“百千万工程”为牵引，有效推动茂名市农产品标准化、规模化、品牌化发展。“湾区认证”是由粤港澳三地共同商议达成的自愿性认证方式，在三地市场上有共同承认、共同流通、共同推广的作用，有利于广东地区的优秀产品及服务走向湾区，走向世界。何金同志强调，一要思想统一，充分认识“湾区认证”的重要意义；二要学有所得，充分领会“湾区认证”内涵，对标对表提升产品质量水平；三要学以致用，充分利用“湾区认证”的背书加持，提升产品市场竞争力。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img11.jpg')}" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">在培训环节，湾区认证促进中心运营总监陆永驰详细介绍了湾区认证的概念、申请条件和流程，以及如何通过质量信息公示、承诺赔付和销售商首付责任等三项制度构建全链条的质量管控体系。通过南阳电缆、禾顺豆芽和三滴水凤凰单丛茶等案例分享，会议展示了湾区认证如何帮助企业提升产品品质和市场竞争力。企业代表们对湾区认证制度有了更深入的理解和认识。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img12.jpg')}" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">最后，茂名市市场监督局科技认证科科长何江霞同志表示，本次培训会课程丰富、深入浅出，有较强的针对性、实践性及指导性，为茂名市“信”字号产品的湾区认证工作提供了巨大的启迪和帮助。</div>`
  },
  {
    id: 'GBACPC002',
    coverPictureUrl: require('@/assets/index/news-img2.png'),
    date: '2024-03-14',
    publishTime: '2024-03-14 15:37:59',
    source: '湾区认证促进中心',
    views: '2256',
    title: '“湾区认证”首批项目公布',
    content: '12月19日，“湾区认证”首批项目公布仪式在深圳河套深港科技创新合作区举行。粤港澳三方共同公布15项“湾区认证”项目，“湾区认证”公共服务平台上线运行，“湾区认证”标志正式启用。',
    htmlContent: `<div style="text-indent: 2rem;margin: 10px 0">“湾区认证”是在市场监管总局和粤港澳有关部门指导下，三地认证机构等经营主体，基于粤港澳大湾区共通执行标准，在装备制造、工业消费品、农食产品和服务业等领域开展的高端品质自愿性认证。</div>\n` +
      `<img style="margin: 10px 0" src="https://gbacc.org.cn/assets/%E8%B5%84%E8%AE%AF%E4%B9%9D%EF%BC%88%E5%9B%BE1%EF%BC%89-78b1302f.png" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">“湾区认证”项目由粤港澳三地认证机构、行业协会、商超平台和龙头企业共同开发，指标参数全面满足三地质量安全等市场准入要求，具体项目由三地经营主体共同组织实施，充分体现了粤港澳共商、共建、共享，有效促进了质量认证领域规则机制对接融合，积极助力粤港澳大湾区一体化发展。</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">“湾区认证”首批15个项目覆盖工业消费品、农食产品和服务业三大领域。在工业消费品领域，开发了“健康空调”“高速固态硬盘”“儿童智能手表”“低蓝光”“高品质商务服装”“健康教室照明”“供港铝型材”“供港混凝土”“健康净饮”共9个认证项目。在农食产品领域，开发了“杏仁饼”“酱油”“草莓”“英德红茶”共4个认证项目。在服务业领域，开发了“高端物业服务”“养老安老服务”共2个认证项目。这些项目充分发挥了质量认证服务产业发展和消费升级的基础性作用，有机融合了广东（珠三角九市）产业化优势和港澳国际化优势，集中体现了“绿色湾区、智慧湾区、健康湾区、源产湾区、品尚湾区”理念，符合粤港澳大湾区打造宜居宜业宜游的优质生活圈的发展方向。</div>` +
      `<img style="margin: 10px 0" src="https://gbacc.org.cn/assets/%E8%B5%84%E8%AE%AF%E4%B9%9D%EF%BC%88%E5%9B%BE2%EF%BC%89-26672bab.png" alt="" width="450" height="300"/>`
  },
  {
    id: 'GBACPC005',
    coverPictureUrl: require('@/assets/index/news-img5.jpg'),
    date: '2024-03-14',
    publishTime: '2024-03-14 15:37:59',
    source: '湾区认证促进中心',
    views: '4038',
    title: '联盟机构 | 中国电研CVC威凯承办的多联机对标达标升级试点启动会成功举办',
    content: '2023年11月16日，由对标达标专项日常办公室主办，中国电研CVC威凯承办的多联机对标达标升级试点启动会在佛山成功召开。来自对标达标专委会、对标达标专项日常办公室、佛山市市场监督管理局、中国电研CVC威凯等相关单位领导出席本次会议。',
    htmlContent: `<div style="text-indent: 2rem;margin: 10px 0">2023年11月16日，由对标达标专项日常办公室主办，中国电研CVC威凯承办的多联机对标达标升级试点启动会在佛山成功召开。来自对标达标专委会、对标达标专项日常办公室、佛山市市场监督管理局、中国电研CVC威凯等相关单位领导出席本次会议。</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">会上，中国电研CVC威凯制冷事业部总经理吴志东在致辞中表示，开展多联机对标达标提升行动具有重要意义。中国电研CVC威凯作为多联机对标达标升级试点的第三方技术机构，将严格按照国家对标达标工作要求，积极构建试点组织协调机制，确保试点工作的顺利进行，推动多联机行业的高质量发展。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img5.jpg')}"  alt="" width="450" height="300"/>` +
      `<div style="text-align: center;font-size: 14px">中国电研CVC威凯制冷事业部总经理   吴志东</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">对标达标工作是贯彻落实《国家标准化发展纲要》《质量强国建设纲要》的重要举措，旨在以先进标准助推传统产业提质增效和新兴产业高质量发展。会上，对标达标专项日常办公室专家详细介绍了“对标达标及试点工作的具体方案”，多联机对标达标升级试点是对标达标创新升级的第一批试点，需要行业内企业、科研院所、第三方机构的共同努力，为完善对标达标长效工作机制提供经验。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img5-2.png')}"  alt="" width="450" height="300"/>` +
      `<div style="text-align: center;font-size: 14px">对标达标专项日常办公室专家介绍对标达标及试点工作的具体方案</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">为了进一步提高我国空调行业的产品监管力度，助力“中国制造”走向全球，接轨国际。2022年12月，《对标达标评价要求 第 1 部分：房间空气调节器》正式发布实施。该标准将空调产品各指标涉及中国标准中的内容，与国际上主流标准的指标内容进行比较（涉及空调产品的安全、性能、能效、噪声、生态设计、智能、高品质等多方面），总结并采纳国际领先水平的指标要求，服务致力于设计和生产国际领先技术水平的空调产品的企业。会议期间，中国标准化协同创新平台人工环境舒适性专委会秘书长齐云为T/CAB 2022.1-2022《对标达标评价要求 第 1 部分：房间空气调节器》的参编单位广东美的制冷设备有限公司、青岛海尔空调器有限总公司、威凯检测技术有限公司进行了授牌。同时，在相关部门领导及企业的见证下，多联式空调（热泵）产品对标达标工作在本次大会上正式启动。</div>` +
      `<img style="margin: 10px 0" src="${require('@/assets/index/news-img5-1.jpg')}"  alt="" width="450" height="300"/>` +
      `<div style="text-align: center;font-size: 14px">对标达标团体标准编制单位授牌仪式</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">行业的高质量发展，离不开多方的协同努力，为推动行业技术水平持续提升，众多家电企业积极参与对标达标工作。本次会议发布了首期家用电器产品对标达标升级试点成果。按照对标方案，经权威第三方技术机构评价，广东美的制冷设备有限公司、青岛海尔空调器有限总公司、上海科慕电器有限公司、佛山市顺德区美的洗涤电器制造有限公司等企业的多个房间空气调节器、洗碗机型号荣获达标证书。</div>`

  },
  {
    id: 'GBACPC004',
    coverPictureUrl: require('@/assets/index/news-img4.png'),
    date: '2023-10-24',
    publishTime: '2023-10-24 11:03:59',
    source: '新华网',
    views: '1039',
    title: '联盟机构 | 高端品质认证，赋能智慧生活——2023年“质量认证在身边”活动成功举办',
    content: '2023年10月24日，由国家市场监督管理总局认证监督管理司主办，中国消费品质量安全促进会承办，中国电研、中国电研CVC威凯、《消费指南》杂志社等单位协办的2023年度“质量认证在身边”公益活动在广州顺利举行。来自国家市场监督管理总局认证监督管理司、广东省市场监督管理局以及广州市黄埔区市场监督管理局等相关负责人、中国消费品质量安全促进会、中国电研CVC威凯等第三方质量质量技术服务机构、企业代表共150余人参加了本次活动。',
    htmlContent: `<div style="text-indent: 2rem;margin: 10px 0">2023年10月24日，由国家市场监督管理总局认证监督管理司主办，中国消费品质量安全促进会承办，中国电研、中国电研CVC威凯、《消费指南》杂志社等单位协办的2023年度“质量认证在身边”公益活动在广州顺利举行。来自国家市场监督管理总局认证监督管理司、广东省市场监督管理局以及广州市黄埔区市场监督管理局等相关负责人、中国消费品质量安全促进会、中国电研CVC威凯等第三方质量质量技术服务机构、企业代表共150余人参加了本次活动。</div>\n` +
      `<img style="margin: 10px 0" src="https://gbacc.org.cn/assets/%E8%B5%84%E8%AE%AF%E4%B8%89%EF%BC%88%E5%9B%BE1%EF%BC%89-9535eea7.png" alt="" width="450" height="300"/>` +
      `<div style="margin: 10px 0;font-weight: bold;font-size: 20px">发挥质量认证力量，推动质量强国建设</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">国家市场监督管理总局认证监督管理司副司长姚雷在致辞中指出，“质量认证在身边”公益活动旨在按照高质量发展的要求，着力提升质量认证工作价值和社会影响，推动各行业加强质量管理，全面提高产品和服务质量，更好满足人民群众对美好生活的需求，推动经济社会高质量发展。一是聚焦职责使命，贯彻落实新时代质量认证工作要求；二是紧贴发展需求，提升质量认证服务高质量发展的工作成效；三是坚持共建共享，共同推动质量强国建设。</div>` +
      `<img style="margin: 10px 0" src="https://gbacc.org.cn/assets/%E8%B5%84%E8%AE%AF%E4%B8%89%EF%BC%88%E5%9B%BE2%EF%BC%89-d076088d.png" alt="" width="450" height="300"/>` +
      `<div style="text-align: center;font-size: 14px">国家市场监督管理总局认证监督管理司副司长 姚雷</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">中国消费品质量安全促进会副理事长兼秘书长王昆指出，质量认证是保障和促进消费品质量安全的基础性技术手段，做好质量认证工作有利于提振人民群众对消费品质量安全的信心。一直以来，中国消费品质量安全促进会积极搭建平台，促进政府部门、行业协会、认证机构与消费者之间的交流和互信，做好认证工作科普宣传，增强社会各方对质量认证的认知度和获得感。</div>`
  },
  {
    id: 'GBACPC001',
    coverPictureUrl: require('@/assets/index/news-img.png'),
    date: '2023-10-22',
    publishTime: '2023-10-22 11:03:59',
    source: '新华网',
    views: '2047',
    title: '“湾区品质 服务全球”——“湾区认证”助力区域贸易便利化发展论坛成功举办',
    content: '10月22日，在第28届澳门国际贸易投资展览会（MIF）上，由广东粤港澳大“湾区认证”促进中心与澳门质量品牌国际认证联盟共同主办的“湾区品质 服务全球”——“湾区认证”助力区域贸易便利化发展论坛成功召开。本次论坛活动是“湾区认证”面向澳门乃至葡语系国家的重要展示、推广、宣传，也是“湾区认证”首次在港澳地区亮相。',
    htmlContent: `<div style="text-indent: 2rem;margin: 10px 0">10月22日，在第28届澳门国际贸易投资展览会（MIF）上，由广东粤港澳大“湾区认证”促进中心与澳门质量品牌国际认证联盟共同主办的“湾区品质 服务全球”——“湾区认证”助力区域贸易便利化发展论坛成功召开。本次论坛活动是“湾区认证”面向澳门乃至葡语系国家的重要展示、推广、宣传，也是“湾区认证”首次在港澳地区亮相。</div>` +
      `<img style="margin: 10px 0" src="https://gbacc.org.cn/assets/%E8%B5%84%E8%AE%AF%E4%B8%80%EF%BC%881%EF%BC%89-0ffd75d4.png" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">活动由广东省市场监督管理局、澳门贸易投资促进局、澳门经济及科技发展局指导，澳门科学技术发展基金支持，澳门工商联会、澳门融贯投资科技有限公司、威凯认证检测有限公司、澳门前沿科技产业协会协办。来自粤港澳40余家单位专家和代表参加论坛活动。</div>` +
      `<img style="margin: 10px 0" src="https://gbacc.org.cn/assets/%E8%B5%84%E8%AE%AF%E4%B8%80%EF%BC%882%EF%BC%89-2e7d51a5.png" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">广东粤港澳大“湾区认证”促进中心理事长赖静女士、澳门质量品牌国际认证联盟理事长冯文滔先生在活动中致辞。赖静理事长表示“湾区认证”是粤港澳大湾区高质量发展的创新实践，粤港澳大湾区的高质量发展与澳门的繁荣息息相关；同时，澳门的高质量发展也为整个大湾区注入了新的活力和创新动能，持续发挥澳门作为对外贸易重要窗口的作用。冯文滔先生期待澳门凭借其在食品、中医药、服务业等领域的丰富经验不断为“湾区认证”注入动力，全面实现“湾区认证”“一个标准（规范）、一次检测、一次认证、三地通行”的愿景。现场“湾区认证”促进中心对澳门地区的理事单位成员等进行了授牌。</div>`
  },
  {
    id: 'GBACPC003',
    coverPictureUrl: require('@/assets/index/news-img3.png'),
    date: '2023-09-06',
    publishTime: '2023-09-06 11:03:59',
    source: '新华网',
    views: '4096',
    title: '“湾区认证”亮相服贸会，联盟理事长赖静受邀出席合格评定服务贸易便利化论坛',
    content: '9月6日，2023年中国国际服务贸易交易会“合格评定服务贸易便利化论坛”在北京举行，国家市场监管总局副局长蒲淳、中国国际贸易促进委员会副会长张少刚出席论坛并致辞，市场监管总局认证监管司副司长姚雷主持论坛。',
    htmlContent: `<div style="text-indent: 2rem;margin: 10px 0">9月6日，2023年中国国际服务贸易交易会“合格评定服务贸易便利化论坛”在北京举行，国家市场监管总局副局长蒲淳、中国国际贸易促进委员会副会长张少刚出席论坛并致辞，市场监管总局认证监管司副司长姚雷主持论坛。</div>` +
      `<img style="margin: 10px 0" src="https://gbacc.org.cn/assets/%E8%B5%84%E8%AE%AF%E4%BA%8C%EF%BC%88%E5%9B%BE1%EF%BC%89-2d375215.png" alt="" width="450" height="300"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">本次论坛围绕“合格评定助力区域对外贸易协同发展”、“合格评定与管理创新”和“合格评定协同先进制造业高质量发展”三个专题开展圆桌交流。赖静理事长在“合格评定助力区域对外贸易协同发展”主题论坛上进行了精彩的发言与论述。</div>` +
      `<img style="margin: 10px 0" src="https://gbacc.org.cn/assets/%E8%B5%84%E8%AE%AF%E4%BA%8C%EF%BC%88%E5%9B%BE2%EF%BC%89-2a1e46f4.png" alt="" width="450" height="500"/>` +
      `<div style="text-indent: 2rem;margin: 10px 0">赖静理事长表示，粤港澳大“湾区认证”联盟联合内地和港澳地区的认证机构、行业组织、采销平台、企业等经营主体共同开展“湾区认证”工作。同时，联盟锚定国家赋予粤港澳大湾区“宜居宜业宜游的优质生活圈”的战略定位，围绕源产湾区、健康湾区、智慧湾区、绿色湾区等板块研发特色认证项目，服务区域内对外贸易发展，讲好湾区宜居宜业宜游的故事，贯彻“湾区品质，服务全球”的联盟品牌理念。</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">未来，联盟将持续发挥粤港澳三地的国际交流合作平台资源和对外贸易优势，推动“湾区认证”获得一带一路国家及葡语系国家的互认与采信，提升“湾区认证”服务贸易便利化水平，为国内国际双循环提供技术支撑。</div>` +
      `<div style="text-indent: 2rem;margin: 10px 0">又悉，本次服贸会上，共有12个认证案例入选了《首批质量认证“小而美”国际互认合作优秀案例》，其中6个优秀案例来自CVC威凯、香港品质保证局、方圆、CQC、SGS、TUV莱茵等粤港澳大“湾区认证”联盟的理事单位，彰显了联盟认证机构卓越的技术底蕴与项目开发实力，为“湾区认证”助力区域对外贸易协同发展奠定了良好的技术基础。</div>`
  }
]
export const companyList = [
  {
    img: require('@/assets/index/company/img3.png')
  },
  {
    img: require('@/assets/index/company/img2.png')
  },
  {
    img: require('@/assets/index/company/img4.png')
  },
  {
    img: require('@/assets/index/company/img1.png')
  },
  {
    img: require('@/assets/index/company/img5.png')
  },
  {
    img: require('@/assets/index/company/img6.png')
  },
  {
    img: require('@/assets/index/company/img7.png')
  },
  {
    img: require('@/assets/index/company/img8.png')
  },
  {
    img: require('@/assets/index/company/img9.png')
  }
]
export const productList = [
  {
    productName: '精制白砂糖',
    certificateNo: 'B8644202406000021',
    productType: '农食产品',
    declarationSubject: '广州华糖食品有限公司',
    address: '广东省广州市',
    baseName: '广州华糖食品有限公司',
    productImg: require('@/assets/index/query/bst.png'),
    certificateImg: require('@/assets/index/query/bst-book.png')
  },
  {
    productName: '蛋黄莲蓉月饼',
    certificateNo: 'B8644202408000037',
    productType: '农食产品',
    declarationSubject: '珠海元朗食品有限公司',
    address: '广东省珠海市',
    baseName: '珠海元朗食品有限公司',
    productImg: require('@/assets/index/query/yb.png'),
    certificateImg: require('@/assets/index/query/yb-book.png')
  },
  {
    productName: '罗非鱼',
    certificateNo: 'B8644202405000013',
    productType: '农食产品',
    declarationSubject: '观星（肇庆）农业科技有限公司',
    address: '广东省肇庆市',
    baseName: '观星（肇庆）农业科技有限公司',
    productImg: require('@/assets/index/query/lfy.png'),
    certificateImg: require('@/assets/index/query/lfy-book.png')
  }
]
export const standardsData = {
  normal: [
    {
      id: 'GBACPC001',
      standardNumber: 'GBACA-TS05-0001-2024-A0',
      releaseTime: '2024-08-01',
      productType: '工业消费品',
      standardState: '现行有效',
      standardName: '化妆品湾区认证实施规则',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/GBACA-IR05-0001-2024-A0_%E5%8C%96%E5%A6%86%E5%93%81%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E5%AE%9E%E6%96%BD%E8%A7%84%E5%88%99.pdf',
      publishTime: '2024-08-01 11:03:59',
      views: '4096'
    },
    {
      id: 'GBACPC006',
      standardNumber: 'GBACA-IR01-0008-2024-A0',
      releaseTime: '2024-07-25',
      productType: '工业消费品',
      standardState: '现行有效',
      standardName: '洗碗机湾区认证实施规则',
      pdfUrl: 'https://minio.wellaudit.cn/bay-area/gbacpc/GBACA-IR01-0008-2024-A0_%E6%B4%97%E7%A2%97%E6%9C%BA%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E5%AE%9E%E6%96%BD%E8%A7%84%E5%88%99.pdf',
      publishTime: '2024-07-25 11:03:59',
      views: '2304'
    },
    {
      id: 'GBACPC007',
      standardNumber: 'GBACA-IR01-0007-2024-A0',
      releaseTime: '2024-07-10',
      productType: '工业消费品',
      standardState: '现行有效',
      standardName: '电动牙刷湾区认证实施规则',
      pdfUrl: 'https://minio.wellaudit.cn/bay-area/gbacpc/GBACA-IR01-0007-2024-A0%20%E7%94%B5%E5%8A%A8%E7%89%99%E5%88%B7%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E5%AE%9E%E6%96%BD%E8%A7%84%E5%88%99.pdf',
      publishTime: '2024-07-10 11:03:59',
      views: '2304'
    },
    {
      id: 'GBACPC002',
      standardNumber: 'GBACA-IR04-0003-2024-A0',
      releaseTime: '2024-06-25',
      productType: '绿色建材',
      standardState: '现行有效',
      standardName: '聚合物水泥防水涂料湾区认证实施规则',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/%E8%81%9A%E5%90%88%E7%89%A9%E6%B0%B4%E6%B3%A5%E9%98%B2%E6%B0%B4%E6%B6%82%E6%96%99%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E5%AE%9E%E6%96%BD%E8%A7%84%E5%88%99%EF%BC%88GBACA-IR04-0003-2024-A0%EF%BC%89.pdf',
      publishTime: '2024-06-25 11:03:59',
      views: '2048'
    },
    {
      id: 'GBACPC003',
      standardNumber: 'GBACA-IR04-0001-2024-A0',
      releaseTime: '2024-05-25',
      productType: '绿色建材',
      standardState: '现行有效',
      standardName: '瓷砖胶湾区认证实施规则',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/GBACA-IR04-0001-2024-A0%20%E7%93%B7%E7%A0%96%E8%83%B6%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E5%AE%9E%E6%96%BD%E8%A7%84%E5%88%99.pdf',
      publishTime: '2024-05-25 11:03:59',
      views: '1042'
    },
    {
      id: 'GBACPC005',
      standardNumber: 'GBACA-IR02-0010-2024-A0',
      releaseTime: '2024-05-06',
      productType: '农食产品',
      standardState: '现行有效',
      standardName: '海水网箱养殖鱼类湾区认证实施规则',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/GBACA-IR02-0010-2024-A0_%E6%B5%B7%E6%B0%B4%E7%BD%91%E7%AE%B1%E5%85%BB%E6%AE%96%E9%B1%BC%E7%B1%BB%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E5%AE%9E%E6%96%BD%E8%A7%84%E5%88%99.pdf?',
      publishTime: '2024-05-06 11:03:59',
      views: '1042'
    },
    {
      id: 'GBACPC004',
      standardNumber: 'GBACA-IR02-0003-2023-A0',
      releaseTime: '2023-12-19',
      productType: '农食产品',
      standardState: '现行有效',
      standardName: '草莓湾区认证实施规则',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/GBACA-IR02-0003-2023-A0_%E8%8D%89%E8%8E%93%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E5%AE%9E%E6%96%BD%E8%A7%84%E5%88%99.pdf',
      publishTime: '2023-12-19 11:03:59',
      views: '2304'
    }
  ],
  special: [
    {
      id: 'GBACPC0011',
      standardNumber: 'GBACA-TS05-0001-2024-A0',
      releaseTime: '2024-07-31',
      productType: '工业消费品',
      standardState: '现行有效',
      standardName: '化妆品湾区认证技术规范',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/GBACA-TS05-0001-2024-A0_%E5%8C%96%E5%A6%86%E5%93%81%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%8A%80%E6%9C%AF%E8%A7%84%E8%8C%83.pdf',
      publishTime: '2024-07-31 11:03:59',
      views: '4096'
    },
    {
      id: 'GBACPC006',
      standardNumber: 'GBACA-TS01-0008-2024-A0',
      releaseTime: '2024-07-25',
      productType: '工业消费品',
      standardState: '现行有效',
      standardName: '洗碗机湾区认证技术规范',
      pdfUrl: 'https://minio.wellaudit.cn/bay-area/gbacpc/GBACA-TS01-0008-2024-A0_%E6%B4%97%E7%A2%97%E6%9C%BA%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%8A%80%E6%9C%AF%E8%A7%84%E8%8C%83.pdf',
      publishTime: '2024-07-25 11:03:59',
      views: '1236'
    },
    {
      id: 'GBACPC016',
      standardNumber: 'GBACA-TS01-0007-2024-A0',
      releaseTime: '2024-07-10',
      productType: '工业消费品',
      standardState: '现行有效',
      standardName: '电动牙刷湾区认证技术规范',
      pdfUrl: 'https://minio.wellaudit.cn/bay-area/gbacpc/GBACA-TS01-0007-2024-A0%20%E7%94%B5%E5%8A%A8%E7%89%99%E5%88%B7%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%8A%80%E6%9C%AF%E8%A7%84%E8%8C%83.pdf',
      publishTime: '2024-07-10 11:03:59',
      views: '2012'
    },
    {
      id: 'GBACPC0066',
      standardNumber: 'GBACA-TS02-0021-2024-A0',
      releaseTime: '2024-06-28',
      productType: '农食产品',
      standardState: '现行有效',
      standardName: '单丛茶湾区认证技术规范',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/GBACA-TS02-0021-2024-A0_%E5%8D%95%E4%B8%9B%E8%8C%B6%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%8A%80%E6%9C%AF%E8%A7%84%E8%8C%83.pdf',
      publishTime: '2024-06-28 11:03:59',
      views: '1042'
    },
    {
      id: 'GBACPC0022',
      standardNumber: 'GBACA-IR04-0003-2024-A0',
      releaseTime: '2024-06-25',
      productType: '绿色建材',
      standardState: '现行有效',
      standardName: '聚合物水泥防水涂料湾区认证技术规范',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/%E8%81%9A%E5%90%88%E7%89%A9%E6%B0%B4%E6%B3%A5%E9%98%B2%E6%B0%B4%E6%B6%82%E6%96%99%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%8A%80%E6%9C%AF%E8%A7%84%E8%8C%83%EF%BC%88GBACA-TS04-0003-2024-A0%EF%BC%89.pdf',
      publishTime: '2024-06-25 11:03:59',
      views: '2048'
    },
    {
      id: 'GBACPC0033',
      standardNumber: 'GBACA-IR04-0001-2024-A0',
      releaseTime: '2024-06-25',
      productType: '绿色建材',
      standardState: '现行有效',
      standardName: '瓷砖胶湾区认证技术规范',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/%E8%81%9A%E5%90%88%E7%89%A9%E6%B0%B4%E6%B3%A5%E9%98%B2%E6%B0%B4%E6%B6%82%E6%96%99%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%8A%80%E6%9C%AF%E8%A7%84%E8%8C%83%EF%BC%88GBACA-TS04-0003-2024-A0%EF%BC%89.pdf',
      publishTime: '2024-06-25 11:03:59',
      views: '1042'
    },
    {
      id: 'GBACPC0055',
      standardNumber: 'GBACA-TS02-0006-2024-A0',
      releaseTime: '2024-03-26',
      productType: '农食产品',
      standardState: '现行有效',
      standardName: '淡水鱼“顺德鱼生”湾区认证技术规范',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/GBACA-TS02-0006-2024-A0_%E6%B7%A1%E6%B0%B4%E9%B1%BC%E2%80%9C%E9%A1%BA%E5%BE%B7%E9%B1%BC%E7%94%9F%E2%80%9D%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%8A%80%E6%9C%AF%E8%A7%84%E8%8C%83.pdf',
      publishTime: '2024-03-26 11:03:59',
      views: '1042'
    },
    {
      id: 'GBACPC0044',
      standardNumber: 'GBACA-TS02-0003-2023-A0',
      releaseTime: '2023-12-19',
      productType: '农食产品',
      standardState: '现行有效',
      standardName: '草莓湾区认证技术规范',
      pdfUrl: 'http://minio.wellaudit.cn/bay-area/gbacpc/GBACA-TS02-0003-2023-A0_%E8%8D%89%E8%8E%93%E6%B9%BE%E5%8C%BA%E8%AE%A4%E8%AF%81%E6%8A%80%E6%9C%AF%E8%A7%84%E8%8C%83.pdf',
      publishTime: '2023-12-19 11:03:59',
      views: '1042'
    }
  ]
}

// 热门数据
export const popularCasesList = getPopularCasesList()

// 获取热门案例数据
function getPopularCasesList() {
// caseLabel
  return CaseCenter.filter((item) => item.caseLabel.split(',').includes('热门案例'))
}

// 最新案例数据
export const newCasesList = getNewCasesList()

// // 获取最新案例数据
function getNewCasesList() {
// caseLabel
  return CaseCenter.filter((item) => item.caseLabel.split(',').includes('最新案例'))
}

// banner左侧色块
export const getBannerLeftBoxList = () => {
  return CategoryList.map((item) => {
    return {
      ...item,
      classList: SubClassList.filter((list) => list.parentId === item.id)
    }
  })
}

// 专题案例数据
export const ThematicCasesList = [
  {
    'id': 'ofw3401hr101',
    'title': '大数据审计',
    'profile': '珠海市审计系统2022年大数据审计技能赛（季度赛）优秀案例精选',
    'locationUnit': '珠海市审计局',
    'personNum': 311,
    'collectNumber': 255,
    'likeNum': 263,
    'collectStatus': 0,
    'caseLabel': '热门案例,最新案例,大数据审计',
    'coverPictureUrl': 'https://qn.wellaudit.cn/sjanlsp/images/course/lQLPJxbXmpax5TvNAgvNA6ywHDAPuE9R5AsDYk_JQwBwAA_940_523.png',
    '所属专题': 'ofw3401hr101'
  },
  {
    'id': 'eqofeh121102',
    'title': '公共投资审计',
    'profile': '公共投资审计案例专题（2022年10月）',
    'locationUnit': '珠海市审计局',
    'personNum': 336,
    'collectNumber': 289,
    'likeNum': 224,
    'collectStatus': 0,
    'caseLabel': '热门案例,最新案例,大数据审计',
    'coverPictureUrl': 'https://qn.wellaudit.cn/sjanlsp/images/course/lQLPJxbYKuk64gfNAgrNA6uwBpCKwJORY2oDYzw-hgBwAQ_939_522.png',
    '所属专题': 'ofw3401hr101'
  }
]
export const friendlyLinkList = [
  {
    linkAddressUrl: 'https://www.audit.gov.cn/',
    name: '中华人民共和国审计署'
  },
  {
    linkAddressUrl: 'http://gdaudit.gd.gov.cn/',
    name: '广东省审计厅'
  },
  {
    linkAddressUrl: 'https://gba.nau.edu.cn/',
    name: '粤港澳大湾区审计研究院（珠海）'
  },
  {
    linkAddressUrl: 'http://audit.zhuhai.gov.cn/',
    name: '珠海市审计局'
  }
]
